/**
 * QR code redirector
 * @module qr
 * @preferred
 */
/** comment to work-around limitation of typedoc module plugin */

// Copyright 2018-2024 Enlightware GmbH, Switzerland

import { NotFoundException, ChecksumException, FormatException } from '@zxing/library';
import { BrowserQRCodeReader } from '@zxing/browser';
import { nonnull } from 'utils/types';

import './qr.scss';

const sources = nonnull(document.getElementById('sources'));

async function decodeContinuously(selectedDeviceId: string) {
	sources.style.display = 'none';
	const codeReader = new BrowserQRCodeReader();
	return codeReader.decodeFromVideoDevice(selectedDeviceId, 'video', (result, err) => {
		if (result) {
			const text = result.getText();
			if (text.startsWith('https://')) {
				console.log(`Redirecting to ${text}`);
				window.location.href = text;
			}
		}
		if (err) {
			if (err instanceof NotFoundException) {
				console.log('No QR code found.')
			} else if (err instanceof ChecksumException) {
				console.log('A code was found, but it\'s read value was not valid.')
			} else if (err instanceof FormatException) {
				console.log('A code was found, but it was in a invalid format.')
			} else {
				console.warn('Unhandled error, reloading', err);
				location.reload();
			}
		}
	})
}

void BrowserQRCodeReader.listVideoInputDevices()
	.then((videoInputDevices) => {
		for (const inputDevice of videoInputDevices) {
			const div = document.createElement('div');
			div.innerText = inputDevice.label;
			div.onclick = () => decodeContinuously(inputDevice.deviceId);
			sources.appendChild(div);
		}
	});